import { DateTime } from "luxon";
import { DayClass, Site, SpecialTariffPricing, TariffType } from "../../generated/graphql";
import { ZONE } from "../constants";
import { isWeekend } from "./date";

export const isSpecialDomain = (site: Site) => {
  return site.specialTariffDomain && window.location.hostname.includes(site.specialTariffDomain)
}

export const getSiteSpecialTariffs = (site: Site | null) => {
  if (!site) return []
  let tariffs: SpecialTariffPricing[] | undefined = []
  if (isSpecialDomain(site)) {
    tariffs = site.specialTariffPricing?.filter(x => x.tariffType === TariffType.Restricted)
  } else {
    tariffs = site.specialTariffPricing?.filter(x => x.tariffType === TariffType.Regular)
  }
  return tariffs ?? []
}

export const isCurrentTariff = (tariff: SpecialTariffPricing) => {
  const timeInAuckland = DateTime.now().setZone(ZONE);
  const startLimit = timeInAuckland.set({
    hour: parseInt(tariff.start.split(":")[0]),
    minute: parseInt(tariff.start.split(":")[1]),
    second: 0,
  });
  let endLimit = timeInAuckland.set({
    hour: parseInt(tariff.end.split(":")[0]),
    minute: parseInt(tariff.end.split(":")[1]),
    second: 0,
  });
  if (tariff.end < tariff.start) {
    endLimit = endLimit.plus({ day: 1 });
  }
  return (
    ((isWeekend(timeInAuckland) && tariff.dayClass === DayClass.Weekend) ||
      (!isWeekend(timeInAuckland) && tariff.dayClass === DayClass.Weekday)) &&
    timeInAuckland > startLimit &&
    timeInAuckland < endLimit
  )
}
