import { DateTime } from "luxon";
import { FeeType, Site } from "../../generated/graphql";
import { isWeekend } from "./date";
import { ZONE } from "../constants";
import { getSiteSpecialTariffs } from "./specialTariff";

const calculateAmountPostPayment = (
  selectedSite: Site | null,
  currentDuration: number,
  startTime: DateTime,
) => {
  let amount = 0
  const currentTimeInAuckland = DateTime.now().setZone(ZONE);
  const startTimeFormat = startTime.toFormat("HH:mm");
  const endTimeFormat = currentTimeInAuckland.toFormat("HH:mm");
  if (selectedSite) {
    const isWeekendNow = isWeekend(currentTimeInAuckland)
    const costPerHour = isWeekendNow
      ? selectedSite.costPerHourWeekend
      : selectedSite.costPerHour;
    const actualDuration = currentDuration - (selectedSite.hasFreeTime ? selectedSite.freeTime : 0);
    console.log('Actual Duration', actualDuration)
    amount =
      Math.round(
        ((actualDuration / 60) * (costPerHour ?? 0)) *
        100
      ) / 100;
    console.log('Hourly Amount', amount)

    const tariffs = getSiteSpecialTariffs(selectedSite);
    
    if (tariffs.length > 0 && !isWeekendNow) {
      console.log('Special Tariff')
      console.log('StartTimeFormat', startTimeFormat)

      const matchingTariffs = tariffs.filter(
        (x) => startTimeFormat <= x.start
      );
      console.log('Matching Tariffs', matchingTariffs)
      if (matchingTariffs.length > 0) {
        const tariffsBeforeEnd = matchingTariffs.filter(x => x.end >= endTimeFormat);
        console.log('Tariffs Before End', tariffsBeforeEnd)
        if (tariffsBeforeEnd.length > 0) {
          const tariff = tariffsBeforeEnd[0];
          amount = tariff.price < amount ? tariff.price : amount;
        }
        const tariffsAfterEnd = matchingTariffs.filter(x => x.end < endTimeFormat);
        console.log('Tariffs After End', tariffsAfterEnd)
        if (tariffsAfterEnd.length > 0) {
          const tariff = tariffsAfterEnd[0];
          const endTime = DateTime.fromFormat(tariff.end, "HH:mm", { zone: ZONE });
          const durationInHours = endTime.diff(currentTimeInAuckland, 'hours').hours;
          amount = tariff.price + (durationInHours * (costPerHour ?? 0));
        }
      }
    }
  }
  return amount
}

export const calculateAmount = (
  selectedSite: Site | null,
  selectedFee: FeeType,
  currentDuration: number,
  reachedMaxDuration: boolean,
  specialTariffSelected: number | null,
  startTime?: DateTime,
) => {
  let amount = 0;
  if (selectedSite?.paymentType === "Postpayment" && startTime) {
    console.log('Calculating post payment amount')
    amount = calculateAmountPostPayment(selectedSite, currentDuration, startTime);
  } else {
    if (selectedSite) {
      if (selectedFee === FeeType.Monthly) {
        amount = selectedSite.monthlyFee;
      } else if (selectedFee === FeeType.Hourly) {
        if (reachedMaxDuration) {
          const currentTimeInAuckland = DateTime.now();
          amount = isWeekend(currentTimeInAuckland)
            ? selectedSite.maxCostWeekend
            : selectedSite.maxCost;
        } else {
          const currentTimeInAuckland = DateTime.now();
          const costPerHour = isWeekend(currentTimeInAuckland)
            ? selectedSite.costPerHourWeekend
            : selectedSite.costPerHour;
          const actualDuration = currentDuration - (selectedSite.hasFreeTime ? selectedSite.freeTime : 0);
          amount =
            Math.round(
              ((actualDuration / 60) * (costPerHour ?? 0)) *
              100
            ) / 100;
        }
      } else if (selectedFee === FeeType.SpecialTariff) {
        const tariffs = getSiteSpecialTariffs(selectedSite);
        const pricing = tariffs.find(
          (x) => x.id === specialTariffSelected
        );
        if (pricing) {
          amount = pricing.price;
        }
      }
    }
  }
  return amount ? amount + (selectedSite?.transactionFee ?? 0) : 0;
};

export const roundToClosestMultiple = (base: number, num: number): number => {
  if (base <= 0) {
    throw new Error("Base number must be greater than 0");
  }

  const quotient = num / base;
  const roundedQuotient = Math.round(quotient);
  return roundedQuotient * base;
}