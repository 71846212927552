import { useState } from "react";
import { StepType } from "../types/step";
import { FeeType, Site, useCheckIfHasTokenMutation, useGetPaymentLinkMutation, usePayWithTokenMutation, UserPlate, useValidateMfaMutation } from "../../generated/graphql";
import { DateTime } from "luxon";
import { TextField, Typography } from "@mui/material";
import { colors } from "../../styles";
import Countdown from "react-countdown";
import { CardToken } from "../types/card-token";

interface SessionInformation {
  vrm: string
  name: string;
  email: string;
  phone: string;
  duration: number;
  receiveEmails: boolean;
  receiveSms: boolean;
  reachedMaxDuration: boolean;
  specialTariffSelected: number | null;
  selectedFee: FeeType;
  checkedCreditCard: boolean;
  selectedCard?: string;
  startTime?: number
}

const MFAComponent: React.FC<{
  mfa: string,
  setMfa: (mfa: string) => void,
}> = ({ mfa, setMfa }) => (
  <TextField
    label="MFA Code"
    value={mfa}
    fullWidth
    onChange={(e) => setMfa(e.target.value)}
  />
)

export const useStepHandler = (
  setError: (message: string) => void,
  bottomRef: React.RefObject<HTMLButtonElement>,
  setTokens: (tokens: CardToken[]) => void,
  setPlates: (plates: UserPlate[]) => void,
  setSelectedCard: (card: string) => void,
  setSelectedVrm: (vrm: string) => void,
  setVrm: (vrm: string) => void,
) => {
  const [step, setStep] = useState<StepType>("initial");
  const [countdown, setCountdown] = useState<Date>();
  const [requestToken, setRequestToken] = useState<boolean>(false);
  const [mfa, setMfa] = useState<string>("");
  const [checkIfHasToken, { loading: hasTokenLoading }] =
    useCheckIfHasTokenMutation();
  const [validateMfa, { loading: validateMfaLoading }] =
    useValidateMfaMutation();
  const [getPaymentLink, { loading: paymentLinkLoading }] =
    useGetPaymentLinkMutation();
  const [payWithToken, { loading: payWithTokenLoading }] =
    usePayWithTokenMutation();

  const processCheckIfHasToken = async (site: Site | null, session: SessionInformation) => {
    const { phone } = session;
    if (!phone) {
      setStep("newVrm");
    } else {
      const result = await checkIfHasToken({
        variables: {
          phone,
          siteId: site?.id ?? -1,
        },
      });
      const { data } = result;
      if (data) {
        const { checkIfHasToken } = data;
        const { hasTokens, error: errorMsg } = checkIfHasToken;
        if (errorMsg) {
          setError(errorMsg);
          return;
        }
        setError("");
        if (!hasTokens) {
          setStep("newVrm");
          await generatePaymentLink(site, session);
        } else {
          setStep("token");
          setCountdown(DateTime.now().plus({ minutes: 5 }).toJSDate());
          setRequestToken(hasTokens);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const processValidateMfa = async (site: Site | null, session: SessionInformation) => {
    const { phone } = session;
    const result = await validateMfa({
      variables: {
        phone,
        token: mfa,
        siteId: site?.id ?? -1,
      },
    });
    const { data } = result;
    if (data) {
      const { validateMfa } = data;
      const {
        successful,
        tokens: cardsTokens,
        plates: userPlates,
        error: errorMsg,
      } = validateMfa;
      if (errorMsg) {
        setError(errorMsg);
        return;
      }
      if (successful) {
        setError("");
        setStep("paytoken");
        setTokens(cardsTokens);
        setPlates(userPlates);
        setSelectedCard(cardsTokens.length > 0 ? cardsTokens[0].id : "");
        setSelectedVrm(userPlates.length > 0 ? userPlates[0].vrm : "new");
        setVrm(userPlates.length > 0 ? userPlates[0].vrm : "");
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const generatePaymentLink = async (site: Site | null, session: SessionInformation) => {
    const { vrm, phone, name, email, duration, receiveEmails, receiveSms, reachedMaxDuration, specialTariffSelected, selectedFee, checkedCreditCard, startTime } = session;
    if (!vrm) {
      setError("The registration number is required.");
      return;
    }
    const result = await getPaymentLink({
      variables: {
        duration,
        name,
        email,
        phone,
        vrm,
        qrCode: site?.qrCode ?? "",
        storePayment: checkedCreditCard,
        receiveEmails,
        receiveSms,
        feeType: selectedFee,
        reachedMaxDuration,
        specialTariffPricingId: specialTariffSelected,
        startTime: startTime,
      },
    });
    const { data } = result;
    if (data) {
      const { getPaymentLink } = data;
      const { error: errorMsg, redirectUrl } = getPaymentLink;
      if (errorMsg) {
        setError(errorMsg);
      } else if (redirectUrl) {
        window.open(redirectUrl, "_self", "noopener,noreferrer");
      }
    } else {
      setError("There was a problem generating the payment link");
    }
  };

  const processPayWithToken = async (site: Site | null, session: SessionInformation) => {
    const { selectedCard, vrm, phone, name, email, duration, receiveEmails, receiveSms, reachedMaxDuration, specialTariffSelected, selectedFee, checkedCreditCard, startTime } = session;
    const result = await payWithToken({
      variables: {
        duration,
        name,
        email,
        phone,
        vrm,
        qrCode: site?.qrCode ?? "",
        storePayment: checkedCreditCard,
        receiveEmails,
        receiveSms,
        token: selectedCard!,
        feeType: selectedFee,
        reachedMaxDuration,
        specialTariffPricingId: specialTariffSelected,
        startTime: startTime,
      },
    });
    const { data } = result;
    if (data) {
      const { payWithToken } = data;
      const { error: errorMsg, redirectUrl } = payWithToken;
      if (errorMsg) {
        setError(errorMsg);
      } else if (redirectUrl) {
        window.open(redirectUrl, "_self", "noopener,noreferrer");
      }
    } else {
      setError("There was a problem processing the payment. Please try again");
    }
  };

  const processPayment = async (site: Site | null, sessionInformation: SessionInformation) => {
    if (step === "initial") {
      await processCheckIfHasToken(site, sessionInformation);
    }
    if (step === "newVrm") {
      await generatePaymentLink(site, sessionInformation);
    }
    if (step === "token") {
      await processValidateMfa(site, sessionInformation);
    }
    if (step === "newpayment") {
      await generatePaymentLink(site, sessionInformation);
    }
    if (step === "paytoken") {
      await processPayWithToken(site, sessionInformation);
    }
  }

  const TimerComponent: React.FC<{}> = () => (
    <>
      <Typography color={colors.blue}>
        We would like to validate your information. Please provide the
        code that has been sent to your phone via SMS before the next 5
        minutes
      </Typography>
      <Typography color={colors.orange}>
        <Countdown
          renderer={({ minutes, seconds }) => {
            return `${minutes.toString().padStart(2, "0")}:${seconds
              .toString()
              .padStart(2, "0")}`;
          }}
          date={countdown}
        ></Countdown>
      </Typography>
    </>
  )

  return {
    step,
    setStep,
    processPayment,
    hasTokenLoading,
    requestToken,
    mfa,
    setMfa,
    MFAComponent,
    TimerComponent,
    validateMfaLoading,
    paymentLinkLoading,
    payWithTokenLoading,
  };
}