import { CarSession, DayClass, FeeType, Site, useFindSessionBySiteAndVrmMutation, UserPlate } from "../../generated/graphql"
import { Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useVrm } from "../../shared/hooks/useVrm";
import { ClipLoader } from "react-spinners";
import { useRef, useState } from "react";
import { ZONE } from "../../shared/constants";
import { DateTime } from "luxon";
import { calculateAmount, roundToClosestMultiple } from "../../shared/utils/calculator";
import { colors } from "../../styles";
import { useReceiveCommunication } from "../../shared/hooks/useReceiveCommunication";
import { Tooltip } from "../../shared/components/Tooltip";
import { StepType } from "../../shared/types/step";
import { useStepHandler } from "../../shared/hooks/useStepHandler";
import { CardToken } from "../../shared/types/card-token";
import { getSiteSpecialTariffs, isCurrentTariff } from "../../shared/utils/specialTariff";
import { isWeekend } from "../../shared/utils/date";
import { getSpecialTariffText } from '../../shared/utils/specialTariffs'

interface IProps {
  site: Site
}

export const PostPayment: React.FC<IProps> = ({ site }: IProps) => {
  const { vrm, VrmComponent, setVrm } = useVrm()
  const [loadingPayment, setLoadingPayment] = useState<boolean>()
  const [checkedCreditCard, setCheckedCreditCard] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [tokens, setTokens] = useState<CardToken[]>([]);
  const [plates, setPlates] = useState<UserPlate[]>([]);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [selectedVrm, setSelectedVrm] = useState<string>("new");
  const [findSessionBySiteAndVrm, { loading: loadingSession }] = useFindSessionBySiteAndVrmMutation()
  const [carSession, setCarSession] = useState<CarSession & { amount: number, duration: number } | null>(null)
  const bottomRef = useRef<null | HTMLButtonElement>(null);
  const { email, setEmail, EmailComponent, phone, setPhone, PhoneComponent, receiveEmails, setReceiveEmails, receiveSms, setReceiveSms, ReceiveEmailComponent, ReceiveSMSComponent } = useReceiveCommunication()
  const { payWithTokenLoading, paymentLinkLoading, validateMfaLoading, requestToken, mfa, setMfa, MFAComponent, TimerComponent, step, setStep, processPayment, hasTokenLoading } = useStepHandler(setError, bottomRef, setTokens, setPlates, setSelectedCard, setSelectedVrm, setVrm)
  const [selectedFee, setSelectedFee] = useState<FeeType>(FeeType.Hourly)
  const [specialTariffSelected, setSpecialTariffSelected] = useState<
      number | null
    >(null);

  const handleSearch = async () => {
    try {
      const result = await findSessionBySiteAndVrm({
        variables: {
          siteId: site.id,
          vrm: vrm
        }
      })
      const { data } = result
      if (data) {
        const { findSessionBySiteAndVrm } = data
        if (!findSessionBySiteAndVrm) {
          setError('No session found')
          return
        }
        if (findSessionBySiteAndVrm.letOut) {
          setError(findSessionBySiteAndVrm.notification ?? 'This car can leave the parking lot')
          return
        } else {
          let currentFeeType = FeeType.Hourly
          const tariffs = getSiteSpecialTariffs(site)
          if (tariffs.length > 0) {
            const currentTarrif = tariffs.find(x => {
              return isCurrentTariff(x)
            })
            if (currentTarrif) {
              currentFeeType = FeeType.SpecialTariff
              setSelectedFee(FeeType.SpecialTariff)
              setSpecialTariffSelected(currentTarrif.id)
            }
          }
          const startTime = DateTime.fromSeconds(findSessionBySiteAndVrm.startTime, { zone: ZONE })
          setError('')
          const duration =
            roundToClosestMultiple(
              site.timeStep,
              (startTime.diffNow().as('minutes') * -1)
            )
          const amount = calculateAmount(site, currentFeeType, duration, false, null, startTime)
          setCarSession({
            ...findSessionBySiteAndVrm,
            amount,
            duration,
          })
        }
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.log('Error', error)
      setError('There was an error searching for the session')
    }
  }

  const handleCreditCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedCreditCard(event.target.checked);
    if (event.target.checked) {
      setReceiveSms(true);
    }
  };

  const canBuy = carSession && carSession.duration > 0
  const loadingButton =
    validateMfaLoading ||
    paymentLinkLoading ||
    hasTokenLoading ||
    payWithTokenLoading;

  const specialTariffsToRender = getSiteSpecialTariffs(site)

  return (
    <Stack spacing={2} width={'100%'} textAlign={'center'}>
      <VrmComponent vrm={vrm} setVrm={setVrm} disabled={false} />
      <Button variant="contained" color="primary" disabled={vrm === ""}
        onClick={handleSearch}>
        {
          loadingSession ? (
            <ClipLoader color={"white"} />
          ) : (
            'Search'
          )
        }
      </ Button>
      {error && <Typography color={"red"}>{error}</Typography>}
      {loadingSession && <ClipLoader size={20} color={"white"} />}
      {
        carSession && (
          <Stack spacing={2} alignItems={'center'}>
            <Typography
              color={colors.blue}
              fontWeight={"bold"}
              fontSize={"1.5rem"}
            >
              Entered at:
            </Typography>
            <Typography
              color={colors.blue}
              fontSize={"1.5rem"}
            >
              {DateTime.fromSeconds(carSession.startTime, { zone: ZONE }).toFormat('yyyy-MM-dd hh:mm a')}
            </Typography>
            <FormGroup>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCreditCardChange}
                      checked={checkedCreditCard}
                    />
                  }
                  label="STORE / USE EXISTING CREDIT CARDS"
                />
                <Tooltip text="Using our payment provider, your credit card information will be stored to facilitate the payment process in future purchases." />
              </Stack>
              {receiveSms && <PhoneComponent phone={phone} setPhone={setPhone} />}
              {!receiveEmails && step === "paytoken" && <EmailComponent email={email} setEmail={setEmail} />}
            </FormGroup>
            {selectedFee === FeeType.SpecialTariff && (
              <Stack
                alignItems={"center"}
                height="5rem"
                justifyContent={"center"}
              >
                {getSpecialTariffText(site, specialTariffsToRender, specialTariffSelected)}
              </Stack>
            )}
            <Button
              fullWidth
              disabled={!canBuy}
              ref={bottomRef}
              sx={{ padding: 2 }}
              variant="contained"
              onClick={() => processPayment(site, {
                vrm,
                name: '',
                email,
                phone,
                duration: carSession.duration,
                receiveEmails,
                receiveSms,
                reachedMaxDuration: false,
                specialTariffSelected: null,
                selectedFee,
                checkedCreditCard,
                selectedCard,
                startTime: carSession.startTime
              })}
            >
              {loadingButton ? (
                <ClipLoader color="white" />
              ) : step === "token" ? (
                "VALIDATE"
              ) : `PAY $${carSession.amount.toFixed(2)}`}
            </Button>
          </Stack>
        )
      }
    </Stack>
  )
}